import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Schedule/SuccessBox';
import SheduleTable from 'components/Web_User_Interface/1080p_Series/Alarm/Schedule/scheduleTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Schedule",
  "path": "/Web_User_Interface/1080p_Series/Alarm/Schedule/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the correct time zone as it will be used by the camera. Remember Daylight-Saving-Time!",
  "image": "./WebUI_1080p_SearchThumb_Alarm_Schedule.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Alarm_Schedule.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Schedule' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the correct time zone as it will be used by the camera. Remember Daylight-Saving-Time!' image='/images/Search/WebUI_1080p_SearchThumb_Alarm_Schedule.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Alarm_Schedule.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Alarm/Zeitplan/' locationFR='/fr/Web_User_Interface/1080p_Series/Schedule/' crumbLabel="Alarm Schedule" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can define a time schedule in which the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Motion Detection`}</a>{` should be active for the active `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Detection Areas`}</a>{`. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Date_Time/"
      }}>{`Correct Time Zone`}</a>{` as it will be used by the camera. Remember Daylight-Saving-Time!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/556a6034a92295cb9a07f011be10370b/8dd93/1080p_Settings_Alarm_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADI0lEQVQ4y22TXWwUVRTHLz74QQlxrYqbqhVsLci6glAVNVXrA2rBD1pUUpsqmK4iGruzOx+725m72/rSxlUC0XbBArEG/DZCABOjSDHxRVMjJgj2xQcTTZFqtl23s8vP3Jm1msaT/HPuOfPP/5x7zlyh63F0XccwTCwjjv5ylhf2fMa2Nz8lkjtK138QmcURnt3zOVr/IHbC5OnNWwjdFGblqtWIWFxHi+uYpkVMi7L9jRzj54qcmZjmx4m/ODUxw6mJIqfPuoz/cZ6fJsuc+d3llxK8t/8jtj78KJvXtdGy+nbWNCxDxHWD7piOZSWIahq7ckO4hSk8O52jfKyV8yc6KY8+Sel4O6XjHcx8sQm+fZ6xgwdIPfES9iPtGGubaAnfgjBNEy1uYFkWmhZlaGiIyclzFFwojj5FISco7JtHYa+YRX63gI+rObZ3J3JTO9lIlPubOgiHGn3BaFyvCGqeYGF6GrcEMz8fpjjWh/tDFvfkKxVkcb8foDy+m7NfjfC1tpLvdmzhnaRG1z0rELphEo35M1SCg4OD5AtF/sxPM1UEFygBZf61fCXY9/qrtNQF6d+wmC8jNXzQsRQR043ZDqPd3QwPDwNFYIq5prL5Ekz99qtXYnt2J9WBIIuuvIblwQDdjdUINT+/QxPDNOnp6WH/wIsc6N/KyMjbjI1+wofvv8vJE4c4cugoxsBbHHwtx2E7w7bOTq4K1lB9WYBLF1zCfYvn+1tWUP9iJpOhubkZIYSH0EJBfcA/r19SxTcPLaBraRV3tbXScPcdXFtXS/C6Gi6efxFVF85jTfACfyled4aB4zhs3NhG4PJFrG+s5Zm1S1h4xdWsaKhH3rgc84Z67g2HuPXBJjY89zitkce47YE7Ca+6mYbQMmqvr0Oo2f0Ds3JlKdP0piW2I0mnJb1S0iclMUdi2TZOqgfpOPT19SKlRKalFyvubIdqy8qnUimP5DjSJzsOjlSQZKQkLf28ih3b8b7LSk5BJCrdeb4i6JHnED3huZC+t23bgzoLtWG1afX81HISiSSmlfCQSCZJJpN+kUqB//NqTIqnbvg3nknLEEBXBrUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/556a6034a92295cb9a07f011be10370b/e4706/1080p_Settings_Alarm_Schedule.avif 230w", "/en/static/556a6034a92295cb9a07f011be10370b/d1af7/1080p_Settings_Alarm_Schedule.avif 460w", "/en/static/556a6034a92295cb9a07f011be10370b/7f308/1080p_Settings_Alarm_Schedule.avif 920w", "/en/static/556a6034a92295cb9a07f011be10370b/19805/1080p_Settings_Alarm_Schedule.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/556a6034a92295cb9a07f011be10370b/a0b58/1080p_Settings_Alarm_Schedule.webp 230w", "/en/static/556a6034a92295cb9a07f011be10370b/bc10c/1080p_Settings_Alarm_Schedule.webp 460w", "/en/static/556a6034a92295cb9a07f011be10370b/966d8/1080p_Settings_Alarm_Schedule.webp 920w", "/en/static/556a6034a92295cb9a07f011be10370b/61eb3/1080p_Settings_Alarm_Schedule.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/556a6034a92295cb9a07f011be10370b/81c8e/1080p_Settings_Alarm_Schedule.png 230w", "/en/static/556a6034a92295cb9a07f011be10370b/08a84/1080p_Settings_Alarm_Schedule.png 460w", "/en/static/556a6034a92295cb9a07f011be10370b/c0255/1080p_Settings_Alarm_Schedule.png 920w", "/en/static/556a6034a92295cb9a07f011be10370b/8dd93/1080p_Settings_Alarm_Schedule.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/556a6034a92295cb9a07f011be10370b/c0255/1080p_Settings_Alarm_Schedule.png",
              "alt": "Web User Interface - 1080p Series - Alarm Schedule",
              "title": "Web User Interface - 1080p Series - Alarm Schedule",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SheduleTable mdxType="SheduleTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      